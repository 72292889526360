@import '@adyen/adyen-web/dist/adyen.css';

#card-container .adyen-checkout__input {
  border: 1px solid #f2f2f2 !important;
  color: #006aff !important;
  transition: none !important;
  padding: 8px 16px !important;
}

#card-container .adyen-checkout__input--error,
#card-container .adyen-checkout__input--error:hover,
#card-container .adyen-checkout__input--invalid,
#card-container .adyen-checkout__input--invalid:hover {
  border-color: #d10244 !important;
}

#card-container .adyen-checkout__input--focus,
#card-container .adyen-checkout__input--focus:hover,
#card-container .adyen-checkout__input:active,
#card-container .adyen-checkout__input:active:hover,
#card-container .adyen-checkout__input:focus,
#card-container .adyen-checkout__input:focus:hover,
#stored-card-container .adyen-checkout__input--focus,
#stored-card-container .adyen-checkout__input--focus:hover,
#stored-card-container .adyen-checkout__input:active,
#stored-card-container .adyen-checkout__input:active:hover,
#stored-card-container .adyen-checkout__input:focus,
#stored-card-container .adyen-checkout__input:focus:hover {
  border: 2px solid #0055ff !important;
  box-shadow: none;
}

#card-container .adyen-checkout__card__holderName__input {
  border: 1px solid #f2f2f2;
  text-transform: uppercase;
}
#stored-card-container .adyen-checkout__input {
  border: 1px solid #f2f2f2;
}

#card-container input[name='holderName']::placeholder,
#card-container .adyen-checkout__checkbox__label {
  font-size: 16px;
  font-weight: 400;
}

#card-container .adyen-checkout__button--pay,
#card-container .adyen-checkout__button--pay:hover,
#stored-card-container .adyen-checkout__button--pay,
#stored-card-container .adyen-checkout__button--pay:hover {
  background-color: #0055ff;
  font-size: 16px;
  border-radius: 8px;
  font-weight: 600;
  line-height: 24px;
}

#card-container .adyen-checkout__button__icon,
#stored-card-container .adyen-checkout__button__icon {
  display: none;
}

#card-container .adyen-checkout__label__text,
#stored-card-container .adyen-checkout__label__text {
  font-size: 14px;
}

#stored-card-container .adyen-checkout__input.adyen-checkout__input--disabled {
  color: #666;
  background-color: #f2f2f2;
}
