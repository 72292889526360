.autocompleteInput {
  border: none;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  width: 100%;
}
.autocompleteInput:focus {
  outline: none;
}

.errorMessage {
  color: #c10404;
  display: block;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
  margin: 8px 0 0 0;
}
