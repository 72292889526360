.statusDot {
  --size: 10px;
  align-items: center;
  background-color: var(--bg);
  border-radius: 50%;
  display: flex;
  height: calc(var(--size) * 2);
  justify-content: center;
  position: relative;
  width: calc(var(--size) * 2);
}

.statusDot::after {
  background-color: var(--fg);
  border-radius: 50%;
  content: '';
  display: block;
  height: var(--size);
  position: absolute;
  width: var(--size);
}
