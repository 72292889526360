#checkout-step-form-wrapper .adyen-checkout__spinner__wrapper,
#checkout-step-form-wrapper .adyen-checkout__spinner__wrapper--inline,
#checkout-step-form-wrapper .adyen-checkout__spinner,
#checkout-step-form-wrapper .adyen-checkout__spinner--large,
#checkout-step-form-wrapper .adyen-checkout__spinner--small,
#checkout-step-form-wrapper .adyen-checkout__spinner--medium,
#checkout-step-form-wrapper .adyen-checkout__button {
  display: none !important;
}

#checkout-step-form-wrapper .adyen-checkout__input {
  border: none !important;
  border-radius: 0% !important;
  border-bottom: 1px solid #b9c4c9 !important;
  box-shadow: none !important;
  color: #006aff !important;
  transition: none !important;
  padding: 8px 8px !important;
  width: 100% !important;
  max-width: 100% !important;
  margin-top: 8px;
}

#checkout-step-form-wrapper .adyen-checkout__label__text {
  color: #2e3545 !important;
  font-size: 18px !important;
  padding-top: 8px !important;
}

#checkout-step-form-wrapper .adyen-checkout__input--focus,
#checkout-step-form-wrapper .adyen-checkout__input--focus:hover,
#checkout-step-form-wrapper .adyen-checkout__input:active,
#checkout-step-form-wrapper .adyen-checkout__input:active:hover,
#checkout-step-form-wrapper .adyen-checkout__input:focus,
#checkout-step-form-wrapper .adyen-checkout__input:focus:hover {
  border: none !important;
  box-shadow: none;
}

#checkout-step-form-wrapper .adyen-checkout__card__brands,
#checkout-step-form-wrapper .adyen-checkout__card__cvc__hint__wrapper {
  display: none !important;
}

#checkout-step-form-wrapper .adyen-checkout__card__exp-cvc {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between !important;
}

#checkout-step-form-wrapper .adyen-checkout__field--expiryDate,
#checkout-step-form-wrapper .adyen-checkout__field__cvc {
  width: 100% !important;
  margin: 0 !important;
}

#checkout-step-form-wrapper .adyen-checkout__field__cvc {
  padding-top: 16px;
}

#checkout-step-form-wrapper .adyen-checkout__input:focus:hover,
#checkout-step-form-wrapper
  .adyen-checkout__input.adyen-checkout__input--large.adyen-checkout__input--focus,
#checkout-step-form-wrapper
  .adyen-checkout__input.adyen-checkout__input--small.adyen-checkout__input--focus {
  border: none !important;
  border-bottom: 1px solid #06f !important;
  box-shadow: none !important;
}

#checkout-step-form-wrapper .adyen-checkout__card__holderName__input {
  border: none;
  border-bottom: 1px solid #b9c4c9;
  font-size: 1.125rem;
  text-transform: uppercase;
  font-weight: 700;
}

#checkout-step-form-wrapper
  .adyen-checkout__field--cardNumber
  .adyen-checkout__input--valid:not(.adyen-checkout__card__cardNumber__input--noBrand)
  + .adyen-checkout-input__inline-validation--valid,
#checkout-step-form-wrapper
  .adyen-checkout__field--cardNumber
  .adyen-checkout__input--error
  .adyen-checkout__card__cardNumber__brandIcon {
  display: none;
}

#checkout-step-form-wrapper
  .adyen-checkout__bacs--confirm
  .adyen-checkout-input__inline-validation--valid {
  display: none;
}
